import React from 'react'

class LeadForm extends React.Component {
  componentDidMount() {
    if (window) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://cdn.pipedriveassets.com/web-form-assets/webforms.min.js`
      const headScript = document.getElementsByTagName('script')[0]
      headScript.parentNode.insertBefore(script, headScript)
    }
  }

  render() {
    return (
      <div
        className="pipedriveWebForms mt-4"
        data-pd-webforms="https://pipedrivewebforms.com/form/da7179826ec24b642d1d010e66a8189b3284480"
      />
    )
  }
}

export default LeadForm
