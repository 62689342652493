import React from 'react'
import Layout from '../layouts'
import SalesBanner from '../images/appointment.jpg'
import CSL from '../images/CSL-Logo-Final.png'
import CSL2 from '../images/CSL-Logo-Final.png'
import CSLcert from '../pdf/Classroom_CSL_Training_Program.pdf'
import TraniImg from '../images/Training-photo-CSL-webpage.jpg'
import Man from '../images/image2.png'
import LeadForm from '../components/leadform'
import Hand from '../images/handshake.jpg'

const SalesLead = () => (
  <Layout head={{ title: 'Certified Sales Leader Training' }}>
    <div className="salesled">
      <div
        id="hero-section"
        className="bg-cover"
        style={{ background: `url("${SalesBanner}")` }}
      >
        <div className="container">
          <div className="slim-content">
            <h1 className="text-red">Certified Sales Leader Training</h1>
            <p className="py-4">
              Our training is the country’s most comprehensive sales leadership
              certification program offered.
            </p>
            <LeadForm />
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <img src={CSL} className="img-fluid" alt="csl" />

            <h3 className="text-red mt-4">
              The Sales Leadership & Manager Training Program that is Guaranteed
              to Pay for Itself!
            </h3>
            <p>
              The Certified Sales Leader designation is the country’s most
              comprehensive sales leadership certification program offered. This
              sales leadership training and certification program delivers
              optimism, hope and security to you and your Sales Leader that your
              sales organization will be transformed.
            </p>
            <a
              href="https://www.salesxceleration.com/events-2/category/csl-training/"
              className="btn red-btn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Find a CSL Class Near Me
            </a>
          </div>
          <div className="col-md-6 align-self-center">
            <div className="sales-video">
              <iframe
                width="100%"
                height="304"
                title="SalesXceleration CSL Certification"
                src="https://www.youtube.com/embed/vgeYjqtWFdE"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-12">
            <div className="bg-grey p-4">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-red mb-3">What CSL Training Covers</h3>
                  <p>
                    Our program consists of 16 lessons, taught over an 8-week
                    period, that covers everything from understanding your
                    client, hiring the right salespeople, building effective
                    sales plans, to developing compensation plans that
                    incentivize desired behavior and so much more. The classroom
                    environment allows for roleplaying, coaching, team
                    exercises, Q&A and networking. Plus, participants leave with
                    a toolkit to assist with the implementation of new processes
                    and procedures. When the lessons are complete, the CSL
                    certification examination is taken; and upon passing,
                    participants can proudly add the CSL designation to their
                    credentials.
                  </p>
                  <a
                    href={CSLcert}
                    className="btn red-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    GET CERTIFICATE
                  </a>
                </div>
                <div className="col-md-6 align-self-center">
                  <img
                    src={TraniImg}
                    alt="training"
                    className="img-fluid mt-sm-30"
                  />
                </div>

                <div className="col-md-12 ">
                  <div className="text-center">
                    <h3 className="text-red mt-5">
                      The Sales Leadership Training Class Covers 16
                      Comprehensive Lessons
                    </h3>
                    <p className="text-red">
                      CSL training elevates a top sales rep or a struggling
                      sales manager to a leader who manages effectively and
                      delivers results.
                    </p>
                    <img
                      src={Man}
                      alt="Man-Standing"
                      className="img-fluid mt-3 "
                    />
                  </div>
                </div>

                <div className="row mt-4 text-center">
                  <div className="col-md-4">
                    <h3 className="mt-4 text-red">100%</h3>
                    <p>
                      of our CSL Trainers have proven Executive Sales Management
                      Field Experience, 10 years minimum
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h3 className="mt-4 text-red">77.3%</h3>
                    <p>
                      business owners will pay more for Sales Leadership
                      Training Taught by an Experienced Sales Leader*
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h3 className="mt-4 text-red">96%</h3>
                    <p>
                      of Sales Managers want Sales Leadership Training* But only
                      80% of companies allocate a budget for it{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="full-width bg-full py-5"
        style={{ background: `url("${Hand}")` }}
      >
        <div className="container py-5 text-center">
          <p className="text-white">
            “This is every tool I wish I would have been trained on in one 16
            Module Course. When you complete your certification you will have
            the foundation to be a successful sales leader at any company”
          </p>
          <p className="text-white">
            Joe Dauskurdas - Founder ProGrowth Advisors
          </p>
        </div>
      </div>
      <div className="full-width bg-grey py-5">
        <div className="text-center">
          <img src={CSL2} className="img-fluid mx-auto mb-2" alt="csl-lgo" />
          <h3 className="text-red">
            I want to know more about the CSL Certification!
          </h3>
          <a href="#leadform" className="btn red-btn mt-3">
            CONTACT ME
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default SalesLead
